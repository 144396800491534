import styled from "styled-components"
import Colors from "src/constants/colors"
import { Layout } from "src/constants/layout"

export const HeaderContainer = styled.div`
	width: 100%;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding-left: ${Layout.mdPadding};
	padding-right: ${Layout.mdPadding};
	background-color: ${Colors.dark};

	@media (max-width: ${Layout.smBreakpoint}px) {
		padding-left: ${Layout.smPadding};
		padding-right: ${Layout.smPadding};
	}
	border-radius: 4px;

	border-bottom: 1px solid ${Colors.gray};
	height: 100px;
`

export const LogoContainer = styled.div`
	flex-direction: column;
	height: 100%;
	padding: ${Layout.mdPadding};
	justify-content: space-evenly;

	&:hover {
		cursor: pointer;
	}
`

export const OptionsContainer = styled.div`
	width: 100px;
	padding: ${Layout.smPadding};
`

export const Text = styled.div`
	font-size: 25px;
`

export const IconContainer = styled.div`
	height: 100%;
	width: 100%;
	flex-direction: row;
	align-items: center;
	justify-content: center;
`

export const MenuContainer = styled.div`
	flex-direction: row;
	justify-content: space-evenly;
	align-items: center;
`

interface MobileMenuProps {
	isDrawerOpen: boolean
}

export const MobileMenuContainer = styled.div<MobileMenuProps>`
	align-items: center;
	padding: 15px;

	&:hover {
		cursor: pointer;
	}

	position: ${(props) => (props.isDrawerOpen ? "fixed" : "absolute")};
	right: ${(props) => (!props.isDrawerOpen ? "0" : "250px")};
	/* top: ${(props) => (props.isDrawerOpen ? "0" : "100%")}; */
`

interface MobileMenuDrawerContainerProps {
	isOpen: boolean
}

export const MobileMenuDrawerContainer = styled.div<MobileMenuDrawerContainerProps>`
	flex-direction: column;
	position: fixed;
	top: 0;
	right: 0;
	position: absolute;
	height: 100%;
	width: 250px;
	background: ${Colors.gray};
	z-index: 2;
`
