import React, { useEffect, useState } from "react"
import { Header } from "src/components/Header"
import { HomeContent } from "src/components/HomeContent"
import useLinks from "src/hooks/useLinks"
import { Wrapper } from "./styles"
import { ScrollArea } from "src/components/ui/scroll-area"
import { BlogPost } from "src/interfaces/BlogPost"
import { getPosts } from "src/services/posts"
import { Card, CardContent } from "src/components/ui/card"
// import { useWindowSize } from "src/hooks/useWindowSize"

export const Home = () => {
	// const { width, height } = useWindowSize()
	const {
		handleProjectClick,
		handleAboutClick,
		handleContactClick,
		handleLinkedInClick,
	} = useLinks()

	const [blogPosts, setBlogPosts] = useState<Array<BlogPost>>([])
	const [selectedPost, setSelectedPost] = useState<BlogPost | null>(null)
	// const fetchPosts = async () => {
	// 	try {
	// 		const response = await getPosts()
	// 		if (isArray(response) && !isEmpty(response)) {
	// 			setBlogPosts(response)
	// 		}
	// 	} catch (error) {
	// 		console.log(error)
	// 	}
	// }

	// useEffect(() => {
	// 	fetchPosts()
	// }, [])

	useEffect(() => {
		if (blogPosts.length > 0) {
			setSelectedPost(blogPosts[0])
		}
	}, [blogPosts])

	return (
		<Wrapper>
			<Header
				handleProjectClick={handleProjectClick}
				handleAboutClick={handleAboutClick}
				handleContactClick={handleContactClick}
			/>
			{/* <HomeContent /> */}

			{/* <ScrollArea className="h-[600px] w-[350px] rounded-md border p-4">
				{blogPosts.map((post) => (
					<Card key={post.id} className="w-[300px]">
						<CardContent>
							<h1>{post.title}</h1>
							<p>{post.description}</p>
						</CardContent>
					</Card>
				))}
			</ScrollArea>
			<Card className="w-[550px]">
				<CardContent>
					<MDEditor.Markdown source={selectedPost?.content} />
				</CardContent>
			</Card> */}
			<iframe
				style={{
					marginTop: "2rem",
					height: "80%",
					width: "80%",
					alignSelf: "center",
				}}
				src="https://fredthiesen.github.io"
			></iframe>
		</Wrapper>
	)
}
