import React from "react"
import { Modal } from "./components/Modal"
import { AppContainer } from "./containers/AppContainer"
import { AppRoutes } from "./routes/AppRoutes"

export default function App() {
	return (
		<AppContainer>
			<Modal />
			<AppRoutes />
		</AppContainer>
	)
}
