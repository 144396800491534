const Colors = {
	blue: "#6184D8",
	gold: "#DEB841",
	dark: "#312F2F",
	gray: "#7F8C8D",
	white: "#FFFDFD",
	backhroundGradientWhite:
		// #F5E3E6 👉🏽 #D9E4F5
		"linear-gradient(180deg, #F5E3E6 0%, #D9E4F5 100%)",
	// error: '#f44336',
	// warning: '#ffeb3b',
	// info: '#2196f3',
	// success: '#4caf50'
}

export default Colors
