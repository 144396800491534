import React from "react"
import Modal from "../Modal"
import { Wrapper, Option } from "./styles"

interface Option {
	label: string
	onClick: () => void
}

interface GameOfLifeMenuProps {
	options: Array<Option>
}

export const GameOfLifeMenu = (props: GameOfLifeMenuProps) => {
	const { options } = props

	const handleClick = (option: Option) => {
		option.onClick()
		Modal.close()
	}

	const renderOptions = () => {
		return (
			<>
				{options.map((option, index) => {
					return (
						<Option key={index} onClick={() => handleClick(option)}>
							{option.label}
						</Option>
					)
				})}
			</>
		)
	}
	return <Wrapper>{renderOptions()}</Wrapper>
}
