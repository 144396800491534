import styled from "styled-components"
import { Layout } from "src/constants/layout"

export const Wrapper = styled.div`
	padding: 20px;
	background-color: #ddd;
	margin-bottom: ${Layout.mdGutter};
	border-radius: 5px;
`

export const Option = styled.button`
	padding: 5px;
	appearance: none;
	border: none;
	margin-bottom: 5px;
	border-radius: 5px;
	cursor: pointer;
	&:hover {
		background-color: #ccc;
	}
`
