import React, { useCallback, useEffect, useState } from "react"
import { GameOfLifeMenu } from "src/components/GameOfLifeMenu"
import { Header } from "src/components/Header"
import Modal from "src/components/Modal"
import { Layout } from "src/constants/layout"
import useLinks from "src/hooks/useLinks"
import { useWindowSize } from "src/hooks/useWindowSize"
import { ContentWrapper } from "src/components/ContentWrapper"
import Colors from "src/constants/colors"
import {
	Button,
	ButtonWrapper,
	Cell,
	GenerationLabel,
	MenuWrapper,
	Wrapper,
	WrapperBoard,
	WrapperButtonWrapper,
	WrapperRow,
} from "./styles"

type Board = Array<Array<1 | 0>>
export const GameOfLife = () => {
	const { handleProjectClick, handleAboutClick, handleContactClick } =
		useLinks()

	const useBoardSize = () => {
		const { width } = useWindowSize()
		const size = width < Layout.mdBreakpoint ? 38 : 50
		return size
	}

	const gliderStructure: Board = [
		[0, 0, 0, 1, 0],
		[0, 1, 0, 1, 0],
		[0, 0, 1, 1, 0],
	]

	const gliderGunStructure: Board = [
		[
			0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
			0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
		],
		[
			0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
			0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
		],
		[
			0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
			1, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
		],
		[
			0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 0, 0, 0, 0, 0, 0, 1, 1,
			0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 0,
		],
		[
			0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0, 0, 1, 1,
			0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 0,
		],
		[
			0, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 1, 0, 0, 0, 1, 1,
			0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
		],
		[
			0, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 1, 0, 1, 1, 0, 0, 0, 0,
			1, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
		],
		[
			0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0,
			0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
		],
		[
			0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0,
			0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
		],
		[
			0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0,
			0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
		],
	]

	const insertStructure = (structure: Board) => {
		const newBoard: Board = [...board]
		structure.forEach((row, i) => {
			row.forEach((cell, j) => {
				newBoard[i][j] = cell
			})
		})
		setBoard(newBoard)
	}

	const randomizeBoard = () => {
		const newBoard: Board = [...board]
		newBoard.forEach((row, i) => {
			row.forEach((cell, j) => {
				newBoard[i][j] = Math.random() > 0.7 ? 1 : 0
			})
		})
		setBoard(newBoard)
	}

	const structureOptions = [
		{ label: "Glider", onClick: () => insertStructure(gliderStructure) },
		{
			label: "Glider Gun",
			onClick: () => insertStructure(gliderGunStructure),
		},
		{ label: "Random", onClick: () => randomizeBoard() },
	]

	const boardSize = useBoardSize()

	const initialBoard: Board = Array(boardSize)
		.fill(null)
		.map(() => Array(boardSize).fill(0))

	const [board, setBoard] = useState<Board>(initialBoard)
	const [generation, setGeneration] = useState(0)
	const [isRunning, setIsRunning] = useState(false)

	useEffect(() => {
		isRunning && setGeneration((prevGeneration) => prevGeneration + 1)
	}, [board])

	const interval = React.useRef<any>(null)

	const isThereLife = (board: Board) => {
		return board.some((row) => row.some((cell) => cell === 1))
	}

	const operations = [
		[0, 1],
		[0, -1],
		[1, 0],
		[-1, 0],
		[1, 1],
		[1, -1],
		[-1, 1],
		[-1, -1],
	]

	useEffect(() => {
		!isThereLife(board) && handleStop()
	}, [board])

	function handleStart() {
		setIsRunning(true)
		if (interval.current === null) {
			interval.current = setInterval(() => {
				createGeneration()
			}, 200)
		}
	}

	function handleStop() {
		setIsRunning(false)
		if (interval.current !== null) {
			clearInterval(interval.current)
			interval.current = null // reset the ref
			setBoard(initialBoard)
			setGeneration(0)
		}
	}

	const handleCellClick = (x: number, y: number) => {
		const newBoard = [...board]
		newBoard[x][y] = newBoard[x][y] ? 0 : 1
		setBoard(newBoard)
	}

	const renderGameOFLifeMenu = () => {
		return <GameOfLifeMenu options={structureOptions} />
	}

	const handleMenu = () => {
		Modal.open({
			renderContent: renderGameOFLifeMenu,
		})
	}

	const getNeighbors = (x: number, y: number, oldBoard: Board) => {
		const neighbors = operations.reduce((acc, [xOffset, yOffset]) => {
			const newX = x + xOffset
			const newY = y + yOffset

			if (
				newX >= 0 &&
				newX < boardSize &&
				newY >= 0 &&
				newY < boardSize
			) {
				if (oldBoard[newX][newY]) {
					acc++
				}
			}

			return acc
		}, 0)

		return neighbors
	}

	const createGeneration = useCallback(() => {
		setBoard((prevBoard) => {
			let newBoard: Board = JSON.parse(JSON.stringify(prevBoard))
			for (let i = 0; i < boardSize; i++) {
				for (let j = 0; j < boardSize; j++) {
					const neighbors = getNeighbors(i, j, prevBoard)
					if (neighbors < 2 || neighbors > 3) newBoard[i][j] = 0
					if (!newBoard[i][j] && neighbors === 3) newBoard[i][j] = 1
				}
			}

			return newBoard
		})
	}, [])

	const renderBoard = () => {
		return board.map((row, x) => {
			return (
				<WrapperRow key={x}>
					{row.map((cell, y) => {
						return (
							<Cell
								key={`${x}-${y}`}
								onClick={() => handleCellClick(x, y)}
								isAlive={!!cell}
								height={boardSize === 38 ? 15 : 25}
							/>
						)
					})}
				</WrapperRow>
			)
		})
	}

	return (
		<Wrapper>
			<Header
				handleProjectClick={handleProjectClick}
				handleAboutClick={handleAboutClick}
				handleContactClick={handleContactClick}
			/>
			<ContentWrapper>
				<WrapperBoard>
					<h1
						style={{
							marginBottom: "1rem",
							color: Colors.gold,
						}}
					>
						Geração: {generation}
					</h1>
					{renderBoard()}
				</WrapperBoard>
				<WrapperButtonWrapper>
					<MenuWrapper>
						<ButtonWrapper>
							<Button onClick={handleStart}>Começar</Button>
							<Button onClick={handleStop}>Parar</Button>
							<Button onClick={handleMenu}>
								Inserir estrutura
							</Button>
						</ButtonWrapper>
						<GenerationLabel>Geração: {generation}</GenerationLabel>
					</MenuWrapper>
				</WrapperButtonWrapper>
			</ContentWrapper>
		</Wrapper>
	)
}
