import styled from "styled-components"
import Colors from "src/constants/colors"
import { Layout } from "src/constants/layout"

export const Wrapper = styled.div`
	width: 100%;
`

export const WrapperBoard = styled.div`
	flex-direction: column;
	padding: ${Layout.mdPadding};
	/* background: ${Colors.backhroundGradientWhite}; */
	width: 100%;
	overflow-x: auto;
	align-items: flex-start;
`

export const WrapperRow = styled.div`
	flex-direction: row;
	padding-left: ${Layout.mdPadding};
	padding-right: ${Layout.mdPadding};
	width: 100%;
`

interface CellProps {
	isAlive: boolean
	height: number
}

export const Cell = styled.button<CellProps>`
	border: none;
	outline: none;
	cursor: pointer;
	width: 25px;
	height: ${({ height }) => height}px;
	border: 1px solid #ddd;
	background: ${(props) => (props.isAlive ? Colors.gold : "white")};
`

export const WrapperButtonWrapper = styled.div`
	position: sticky;
	bottom: 0;
	padding: 10px;
	align-self: center;
`

export const MenuWrapper = styled.div`
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	background: rgba(49, 47, 47, 0.6);
	box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.6);
	backdrop-filter: blur(11px);
	-webkit-backdrop-filter: blur(11px);
	border-radius: 4px;
	border: 1px solid rgba(255, 255, 255, 0.18);
`

export const ButtonWrapper = styled.div`
	flex-direction: row;
`

export const Button = styled.button`
	border: none;
	outline: none;
	cursor: pointer;
	background: #ddd;
	padding: 10px;
	margin: 10px;
	border-radius: 5px;
	font-size: 1.2rem;
	font-weight: bold;
	color: #333;
	&:hover {
		background: #ccc;
	}
`

export const GenerationLabel = styled.div`
	font-size: 1.2rem;
	font-weight: bold;
	color: ${Colors.white};
	padding-right: 10px;
	padding-left: 10px;
	margin-left: 10px;
	text-align: center;
`
