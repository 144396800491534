import styled from "styled-components"
import { Layout } from "src/constants/layout"
import backgroundPattern from "src/assets/subtle-prism.svg"

export const Wrapper = styled.div`
	width: 100%;
	height: 100%;
	background-image: linear-gradient(rgba(255,255,255,0.3), rgba(255,255,255,0.3)),url(${backgroundPattern});
	background-size: cover;
	width: 100%;
	flex: 1;
	padding: ${Layout.mdPadding};
	/* align-items: center; */
`
