import { memo } from "react"
import { MenuLabel } from "../MenuLabel"
import {
	CloseLabel,
	CloseLabelContainer,
	MobileMenuDrawerContainer,
} from "./styles"

interface RenderMobileMenuProps {
	isDrawerOpen: boolean
	onClick: () => void
	handleProjectClick: () => void
	handleAboutClick: () => void
	handleContactClick: () => void
	toggleVisbility: () => void
}

export const MobileMenu = memo(
	({
		onClick,
		isDrawerOpen,
		handleProjectClick,
		handleAboutClick,
		handleContactClick,
		toggleVisbility,
	}: RenderMobileMenuProps) => {
		if (!isDrawerOpen) return null

		const renderCloseLabel = () => {
			return (
				<CloseLabelContainer onClick={toggleVisbility}>
					<CloseLabel>Fechar</CloseLabel>
				</CloseLabelContainer>
			)
		}
		return (
			<MobileMenuDrawerContainer isOpen={isDrawerOpen}>
				<MenuLabel title="github" onClick={handleProjectClick} />
				<MenuLabel title="sobre" onClick={handleAboutClick} />
				<MenuLabel title="contato" onClick={handleContactClick} />
				{renderCloseLabel()}
			</MobileMenuDrawerContainer>
		)
	}
)
