import { BrowserRouter, Routes, Route } from "react-router-dom"
import { GameOfLife } from "../pages/GameOfLife"
import { Home } from "../pages/Home"

export const AppRoutes = () => {
	return (
		<BrowserRouter>
			<Routes>
				<Route index element={<Home />} />
				<Route path="/game-of-life" element={<GameOfLife />} />
			</Routes>
		</BrowserRouter>
	)
}
