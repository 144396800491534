import styled from "styled-components"
import Colors from "src/constants/colors"
import { Layout } from "src/constants/layout"

export const LabelContainer = styled.div`
	padding: ${Layout.smPadding};
	font-size: 20px;
	text-align: center;
	margin-right: ${Layout.lgPadding};
	border-bottom: 1px solid transparent;
    
	&:hover {
		cursor: pointer;
		animation: animateBottomBorder 0.3s forwards ease-out;

        @keyframes animateBottomBorder {
            0% {
                border-bottom: 1px solid ${Colors.gray};
            }
            100% {
                border-bottom: 1px solid ${Colors.gold};
            }
	}
`
