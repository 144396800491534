import React from "react"
import { LabelContainer } from "./styles"

interface MenuLabelProps {
	onClick: () => void
	title: string
}

export const MenuLabel = ({ onClick, title }: MenuLabelProps) => {
	return <LabelContainer onClick={onClick}>{title}</LabelContainer>
}
