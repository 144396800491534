import React from "react"

export default function useLinks() {
	const handleProjectClick = () => {
		window.open("https://github.com/fredthiesen", "_blank")
	}

	const handleAboutClick = () => {
		window.open("https://fredthiesen.github.io", "_blank")
	}

	const handleContactClick = () => {
		window.open("mailto:ricardothiesen@outlook.com", "_blank")
	}
	const handleLinkedInClick = () => {
		window.open("https://www.linkedin.com/in/rthiesen/", "_blank")
	}
	return {
		handleProjectClick,
		handleAboutClick,
		handleContactClick,
		handleLinkedInClick,
	}
}
