import { memo, useState } from "react"
import { Layout } from "src/constants/layout"
import { useWindowSize } from "src/hooks/useWindowSize"
import { MenuLabel } from "../MenuLabel"
import { MdMenu } from "react-icons/md"

import {
	HeaderContainer,
	LogoContainer,
	MenuContainer,
	MobileMenuContainer,
	Text,
} from "./styles"
import { MobileMenu } from "../HeaderMobileMenu"

interface HeaderProps {
	handleProjectClick: () => void
	handleAboutClick: () => void
	handleContactClick: () => void
}

export const Header = memo((props: HeaderProps) => {
	const { handleProjectClick, handleAboutClick, handleContactClick } = props
	const [mobileMenuActive, setMobileMenuActive] = useState(false)
	const { width } = useWindowSize()
	const handleMenuDrawer = () => {
		setMobileMenuActive(!mobileMenuActive)
	}

	const renderMobileMenu = () => {
		return (
			<MobileMenuContainer
				onClick={handleMenuDrawer}
				isDrawerOpen={mobileMenuActive}
			>
				<MdMenu size={35} />
			</MobileMenuContainer>
		)
	}

	const renderDesktopMenu = () => {
		return (
			<MenuContainer>
				<MenuLabel title="github" onClick={handleProjectClick} />
				<MenuLabel title="sobre" onClick={handleAboutClick} />
				<MenuLabel title="contato" onClick={handleContactClick} />
			</MenuContainer>
		)
	}

	return (
		<HeaderContainer>
			<LogoContainer onClick={() => (window.location.href = "/")}>
				<Text>{"Ricardo Thiesen"}</Text>
			</LogoContainer>

			{width >= Layout.mdBreakpoint ? (
				<>{renderDesktopMenu()}</>
			) : (
				<>
					<MobileMenu
						isDrawerOpen={mobileMenuActive}
						toggleVisbility={handleMenuDrawer}
						handleAboutClick={handleAboutClick}
						handleContactClick={handleContactClick}
						handleProjectClick={handleProjectClick}
						onClick={handleMenuDrawer}
					/>
					{renderMobileMenu()}
				</>
			)}
		</HeaderContainer>
	)
})

// const handleLinkedinClick = () => {
// 	window.open("https://www.linkedin.com/in/rthiesen/", "_blank")
// }
// const handleGithubClick = () => {
// 	window.open("https://www.github.com/fredthiesen", "_blank")
// }
{
	/* <IconContainer>
					<FaLinkedinIn
						color={Colors.white}
						size={30}
						onClick={handleLinkedinClick}
					/>
					<FaGithub
						color={Colors.white}
						size={30}
						onClick={handleGithubClick}
						style={{ marginLeft: "1rem" }}
					/>
				</IconContainer> */
}
