export const Layout = {
	smGutter: "1.0rem",
	mdGutter: "1.5rem",
	lgGutter: "2rem",

	smMargin: "0.5rem",
	mdMargin: "1.5rem",
	lgMargin: "2rem",

	smPadding: "0.5rem",
	mdPadding: "1.5rem",
	lgPadding: "2rem",

	smBoxShadow: "0 0.05rem 0.1rem 0 rgba(0, 0, 0, 0.1)",
	mdBoxShadow: "0 0.1rem 0.2rem 0 rgba(0, 0, 0, 0.1)",
	lgBoxShadow: "0 0.2rem 0.4rem 0 rgba(0, 0, 0, 0.1)",

	smBreakpoint: 576,
	mdBreakpoint: 768,
	lgBreakpoint: 992,
	xlBreakpoint: 1200,
}
